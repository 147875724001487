import React from 'react';
import { Card, CardBody, CardLink, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import Flag from 'react-world-flags';
import {Buffer} from 'buffer';
import { BsDiscord } from 'react-icons/bs';
import {IconContext} from "react-icons";
import { MdOutlineBuild } from 'react-icons/md';
import { MdOutlineRememberMe } from 'react-icons/md';
import { MdOutlineSupport } from 'react-icons/md';
import { MdOutlineExtension } from 'react-icons/md';
import { FaPuzzlePiece } from "react-icons/fa";
import { BsPuzzle } from "react-icons/bs";
import { FaTwitch } from "react-icons/fa";
import { GrUnlock } from 'react-icons/gr';
import { GrLock } from 'react-icons/gr';
// kick.com
import { SiKick } from "react-icons/si";
import { TbBrandKick } from "react-icons/tb";



function getSteamConnect(ip, port) {
    // return "steam://run/736590//+connect " + ip + ":" + port
    return "https://ohdservers.net/launcher/" + ip + ":" + port
  }

function returnBase64(servername) {
const encodedString = Buffer.from(servername).toString('Base64');
return encodedString
}

const TitleCard = (props) => {
  return (     
    <div>
      <Row style={{margin: '0px', padding: '0px'}} className="cardheaderinfo">
          { props.servers.map((s,index) => (
              <Col key={index} lg="3" md="4" xs="12" className="block">
                  <Card color={(!props.darkmode) ? ("light " + s.map.replace(/[ '.,:()/_]/g,'')) : ("secondary "+ s.map.replace(/[ '.,:()/_]/g,''))} key={index} className={(s.players >= s.max_players) ? ("cardtitle_light") : ("cardtitle_dark")}>
                  <a href={/server/ + returnBase64(s.name)} target="_self">
                    <CardHeader className={(!props.darkmode) ? ("cardheader") : ("cardheader_dark")} style={{minHeight: '80px', maxHeight: '80px', margin: '0px', padding: '0px'}}>
                          <Flag code={ s.scountry } height="16" /> {s.name}
                          <div className="cardheaderinfo">INFO</div>
                    </CardHeader>
                  </a>
    
                    <a href={getSteamConnect(s.sip,s.port)}>
                    <CardBody className={(!props.darkmode) ? ("cardbody") : ("cardbody_dark")} style={{margin: '0px', padding: '0px'}}>
                      {s.players} / {s.max_players} - {s.gamemode} - Join AO: {s.map}
                      <div className="cardbodyinfo">JOIN</div>
                    </CardBody></a>
                    <CardFooter style={{background: 'rgba(255, 255, 255, 0.3)'}}>
                    {s.name.includes('k@') ? (
                                  <IconContext.Provider value={{ className: "kickOn"  }}>
                                    <a style={{marginRight: "1px"}} href={"https://kick.com/" + s.name.split("k@")[1].split(" ")[0]}><TbBrandKick /></a>
                                  </IconContext.Provider>

                          ) : <IconContext.Provider value={{ className: "kickOff"  }}><TbBrandKick /> </IconContext.Provider>
                      }

                    {s.name.includes('twitch@') ? (
                                  <IconContext.Provider value={{ className: "twitchOn"  }}>
                                    <a style={{marginRight: "1px"}} href={"https://twitch.tv/" + s.name.split("twitch@")[1].split(" ")[0]}><FaTwitch /></a>
                                  </IconContext.Provider>

                          ) : <IconContext.Provider value={{ className: "twitchOff"  }}><FaTwitch /> </IconContext.Provider>
                      }

                      {s.name.includes('discord.gg/') ? (
                                  <IconContext.Provider value={{ className: "discordOn"  }}>
                                    <a style={{marginRight: "1px"}} href={"https://discord.gg/" + s.name.split("discord.gg/")[1].split(" ")[0]}><BsDiscord /></a>
                                  </IconContext.Provider>

                          ) : s.name.includes('DSC.GG/') ? (

                          <IconContext.Provider value={{ className: "discordOn"  }}>
                            <a style={{marginRight: "1px"}} href={"https://dsc.gg/" + s.name.split("DSC.GG/")[1].split(" ")[0]}><BsDiscord /></a>
                          </IconContext.Provider>

                          ) : s.name.includes('discord.me/') ? (

                            <IconContext.Provider value={{ className: "discordOn"  }}>
                              <a style={{marginRight: "1px"}} href={"https://discord.me/" + s.name.split("discord.me/")[1].split(" ")[0]}><BsDiscord /></a>
                            </IconContext.Provider>

                          ) : s.name.includes('dsc.gg/') ? (

                            <IconContext.Provider value={{ className: "discordOn"  }}>
                              <a style={{marginRight: "1px"}} href={"https://dsc.gg/" + s.name.split("dsc.gg/")[1].split(" ")[0]}><BsDiscord /></a>
                            </IconContext.Provider>

                          ) : s.name.includes('jpnhq.net') ? (
                                                                              
                            <IconContext.Provider value={{ className: "discordOn"  }}>
                              <a style={{marginRight: "1px"}} href={"https://discord.jpnhq.net"}><BsDiscord /></a>
                            </IconContext.Provider>

                          ) : s.name.includes('RustyRifles') ? (
                                                    
                            <IconContext.Provider value={{ className: "discordOn"  }}>
                              <a style={{marginRight: "1px"}} href={"http://rustyrifles.uk"}><BsDiscord /></a>
                            </IconContext.Provider>

                          
                          ) : s.name.includes('LoyalGames.Eu') ? (
                          
                            <IconContext.Provider value={{ className: "discordOn"  }}>
                              <a style={{marginRight: "1px"}} href={"http://discord.loyalgames.eu/"}><BsDiscord /></a>
                            </IconContext.Provider>
                          
                          ) : s.name.includes('preunk.com/discord') ? (

                            <IconContext.Provider value={{ className: "discordOn"  }}>
                              <a style={{marginRight: "1px"}} href="https://preunk.com/discord"><BsDiscord /></a>
                            </IconContext.Provider>

                            ) : <IconContext.Provider value={{ className: "discordOff"  }}><BsDiscord /> </IconContext.Provider>
                      }

                      {/* <IconContext.Provider value={{ className: "discordOff"  }}><BsDiscord /> </IconContext.Provider> */}

                      {/* server modded logic  */}
                      { s.modded === 'true'
                        ? (<IconContext.Provider value={{ className: "modded"  }}>< MdOutlineBuild /></IconContext.Provider>) :
                          (<IconContext.Provider value={{ className: "unmodded"  }}>< MdOutlineBuild /></IconContext.Provider>)
                      }
                      {/* <IconContext.Provider value={{ className: "unmodded"  }}>< MdOutlineBuild /></IconContext.Provider> */}

                      {/* mUrl logic */}
                      {/* {s.name.includes('ugc/') ? (
                        <IconContext.Provider value={{ className: "selected"  }}>
                          <a href={"https://ohdservers.net/mods/" + s.name.split("ugc/")[1].split(" ")[0]} target="_new"><BsPuzzle /></a>
                        </IconContext.Provider>
                      ) : <IconContext.Provider value={{ className: "unselected"  }}><BsPuzzle /> </IconContext.Provider>
                      } */}

                      {/* <IconContext.Provider value={{ className: "unselected" }}> <MdOutlineExtension /> </IconContext.Provider> */}
                      {/* Server whitelist logic */}
                      { s.whitelist === 1
                        ? (<IconContext.Provider value={{ className: "selected" }}> <MdOutlineRememberMe /> </IconContext.Provider>) :
                          (<IconContext.Provider value={{ className: "unselected" }}> <MdOutlineRememberMe /> </IconContext.Provider>)
                      }
                      {/* <IconContext.Provider value={{ className: "unselected" }}> <MdOutlineRememberMe /> </IconContext.Provider> */}

                      {/* Server OHD supporters logic */}
                      { s.supporter === 'true'
                        ? (<IconContext.Provider value={{ className: "selected" }}> <MdOutlineSupport /> </IconContext.Provider>) :
                          (<IconContext.Provider value={{ className: "unselected" }}> <MdOutlineSupport /> </IconContext.Provider>)
                      }

                      {s.password  === "false" ? <GrUnlock /> : <GrLock />}

                      <div style={{"float":"right"}}>version {s.keywords.split(':')[1]}</div>


                    </CardFooter>
                  </Card>
              </Col>
          ))}
      </Row>
    </div>
  );
};

export default TitleCard;